import api from "./AxiosInstance";

export const GetDropDownApiState = async () => api.get(`bsp/states`);
// export const GetDropDownApiDistrict = async (value) => api.get(`bsp/state_districts`, {
//     params: {
//         state: value
//     }
// });
// export const GetDropDownApiVidhanShabha = async (state, district) => api.get(`bsp/district_constitutions`, {
//     params: {
//         state: state,
//         district: district
//     }
// });


export const PostFormData = async (formData) => api.post(`/register`,
    formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

