import React, { useEffect, useRef, useState } from 'react'
import styles from './VolunteerForm.module.css';
import axios from 'axios';
import { Card, Row, Col, notification, Select, Button, Input } from 'antd';
import { useTranslation } from "react-i18next";
import { GetDropDownApiState, GetDropDownApiVidhanShabha, PostFormData, GetDropDownApiDistrict } from '../Apis/CommonApis';
import { TbUpload } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { MdDelete } from "react-icons/md";
import Modal from 'antd/es/modal/Modal';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import LanguageSelector from './LanguageSelector';



export default function VolunteerForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [validationErrors, setValidationErrors] = useState({});
    const formData = new FormData();
    // const geolocationAPI = navigator.geolocation
    const [items, setItems] = useState(['Doctor', 'Student', 'Teacher', 'Engineer', 'Business', 'Lawyer', 'Accountant']);
    const [name, setName] = useState('');
    const [apii, contextHolder] = notification.useNotification();
    // const [image1, setImage1] = useState("");
    // const [imageback, setImageback] = useState(false);
    // const [image2, setImage2] = useState("");
    const [modalhistory, setModalhistory] = useState(false);
    const [modalhistory2, setModalhistory2] = useState(false);
    const [errors, setErrors] = useState({});
    const [dropdowndata, setDropdowndata] = useState([]);
    // const [dropdowndata2, setDropdowndata2] = useState([]);
    // const [dropdowndata3, setDropdowndata3] = useState([]);
    // const [position, setPosition] = useState("false");
    const [formData1, setFormData1] = useState({});
    const [address, setAddress] = useState('');
    const [familyData, setFamilyData] = useState([{ name: '', dob: '', profession: '', relation: '', mobile: '' },]);
    const [filledData, setFilledData] = useState([]);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        if (name !== "") {
            setItems([...items, name]);
            setName('');
        }
    };
    // const handleInputChangeofTable = (index, fieldName, value) => {
    //     const updatedFilledData = [...filledData];
    //     const existingDataIndex = updatedFilledData.findIndex((data) => data.key === index + 1);
    //     if (fieldName === "name" && isNumeric(value)) {
    //         validationErrors.name = "Enter Valid Name"
    //     } else if (fieldName === "name" && !isNumeric(value)) {
    //         const updatedObject = { ...validationErrors };
    //         delete updatedObject[fieldName];
    //         setValidationErrors(updatedObject);
    //     }
    //     if (fieldName === "mobile" && !isNumeric(value)) {
    //         validationErrors.mobile = "Mobile number should only contain numbers"
    //     } else if (fieldName === "mobile" && isNumeric(value) && (value.length < 10 || value.length > 10)) {
    //         validationErrors.mobile = "Enter Valid Number"
    //     } else if (fieldName === "mobile" && isNumeric(value) && (value.length === 10)) {
    //         const updatedObject = { ...validationErrors };
    //         delete updatedObject[fieldName];
    //         setValidationErrors(updatedObject);
    //     }
    //     if (existingDataIndex !== -1) {
    //         updatedFilledData[existingDataIndex] = {
    //             key: index + 1,
    //             ...updatedFilledData[existingDataIndex],
    //             [fieldName]: value,
    //         };
    //     } else {
    //         updatedFilledData.push({ key: index + 1, [fieldName]: value, name: "", relation: "", dob: "", profession: "", mobile: "" });
    //     }

    //     setFilledData(updatedFilledData);
    //     setFamilyData(updatedFilledData);
    // };
    // const addRow = () => {
    //     setFamilyData([...familyData, { name: '', dob: '', profession: '', relation: '', mobile: '' }]);
    // };
    // const removeRow = (index) => {
    //     if (index > 0) {
    //         const updatedData = familyData.filter((_, i) => i !== index);
    //         setFamilyData(updatedData);
    //     }

    // };
    const submitpage = (data) => {
        navigate(`/submitted`, { state: data });
    }
    // const getUserCoordinates = async () => {
    //     const updatedObject = { ...validationErrors };
    //     delete updatedObject["location"];
    //     setValidationErrors(updatedObject);

    //     if (!geolocationAPI) {
    //         openNotification('Geolocation API is not available in your browser!')
    //     } else {
    //         geolocationAPI.getCurrentPosition((position) => {
    //             const { coords } = position;
    //             convertCoordinatesToAddress(coords.latitude, coords.longitude);
    //             setFormData1((prevData) => ({ ...prevData, location: { long: coords.longitude, lat: coords.latitude } }));
    //         })
    //     }
    // }
    // const convertCoordinatesToAddress = async (lat, lon) => {
    //     try {
    //         const apiKey = 'AIzaSyBe9SddacNNVbpXJd0tMS58iCOFhFwj_Ng';
    //         const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;

    //         const response = await axios.get(apiUrl);

    //         if (response.data.results.length > 0) {
    //             setAddress(response.data.results[0].formatted_address);
    //         } else {
    //             setAddress('No results found');
    //         }
    //     } catch (error) {
    //         console.error('Error converting coordinates to address:', error);
    //     }
    // };
    const openNotification = (msg) => {
        apii.info({
            message: `${msg}`,
        });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        formData.delete(name);
        if (name === "first_name" && !isOnlyChar(value)) {
            validationErrors.first_name = "Enter Valid Name"

        } else if (name === "first_name" && isOnlyChar(value) && !isOnlyCapFirst(value)) {
            validationErrors.first_name = "First letter should be capital"

        } else if (name === "first_name" && !isNumeric(value)) {
            const updatedObject = { ...validationErrors };
            delete updatedObject[name];
            setValidationErrors(updatedObject);
        }
        if (name === "dob" && value > getCurrentDate()) {
            validationErrors.dob = "Enter Valid date"

        } else if (name === "dob") {
            const updatedObject = { ...validationErrors };
            delete updatedObject[name];
            setValidationErrors(updatedObject);
        }
        // if (name === "location") {
        //     const updatedObject = { ...validationErrors };
        //     delete updatedObject[name];
        //     setValidationErrors(updatedObject);
        // }
        // if (name === "guardian_name") {
        //     const updatedObject = { ...validationErrors };
        //     delete updatedObject[name];
        //     setValidationErrors(updatedObject);
        // }
        if (name === "mobile_no" && !isNumeric(value)) {
            validationErrors.mobile_no = "Mobile Number should only contain numbers"
        } else if (name === "mobile_no" && isNumeric(value)) {
            const updatedObject = { ...validationErrors };
            delete updatedObject[name];
            setValidationErrors(updatedObject);
        }

        // if (name === "middle_name") {
        //     const updatedObject = { ...validationErrors };
        //     delete updatedObject[name];
        //     setValidationErrors(updatedObject);
        // }
        // if (name === "last_name") {
        //     const updatedObject = { ...validationErrors };
        //     delete updatedObject[name];
        //     setValidationErrors(updatedObject);
        // }

        setFormData1((prevData) => ({ ...prevData, [name]: value }));
    };
    const isNumeric = (str) => {
        // Regular expression to match only numbers
        const numericRegex = /^[0-9]+$/;

        return numericRegex.test(str);
    };
    const isOnlyChar = (str) => {
        const hindiEnglishRegex = /^[\p{L}\p{Script=Devanagari}\p{L}\p{Script=Latin}\s]*$/u;
        // const numericRegex = /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/;
        // Test if the string matches the pattern
        return hindiEnglishRegex.test(str);
    };
    const isOnlyCapFirst = (str) => {
        const numericRegex = /^[\p{Lu}\p{Script=Devanagari}][\p{L}\p{Script=Devanagari}\s]*$/u;
        // Test if the string matches the pattern
        return numericRegex.test(str);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        // const cleanedFilledData = filledData.map(({ key, ...rest }) => rest);

        formData.append("first_name", formData1?.first_name);
        formData.append("middle_name", formData1?.middle_name ? formData1?.middle_name : "");
        formData.append("last_name", formData1?.last_name ? formData1?.last_name : "");
        formData.append("guardian_name", formData1?.guardian_name ? formData1?.guardian_name : "");
        formData.append("gender", formData1?.gender ? formData1?.gender : "");
        formData.append("profession", formData1?.profession ? formData1?.profession : "");
        formData.append("dob", formData1?.dob ? formData1?.dob : "");
        formData.append("category", formData1?.category ? formData1?.category : "");
        formData.append("caste", formData1?.caste ? formData1?.caste : "");
        formData.append("state", formData1?.state);
        formData.append("district", formData1?.district ? formData1?.district : "");
        formData.append("vidhansabha", formData1?.vidhansabha ? formData1?.vidhansabha : "");
        formData.append("fb_profile_link", formData1?.facebookUrl ? formData1?.facebookUrl : "");
        formData.append("twitter_profile_link", formData1?.twitterUrl ? formData1?.twitterUrl : "");
        formData.append("location", formData1?.location ? formData1?.location : "");
        formData.append("mobile_no", formData1?.mobile_no);
        formData.append("voter_id", formData1?.voter_id ? formData1?.voter_id : "");

        formData.append("anniversary_date", formData1?.anviDate ? formData1?.anviDate : "");
        formData.append("marital_status", formData1?.maritalStatus ? formData1?.maritalStatus : "");
        formData.append("exist_with_us", formData1?.exist_with_us ? formData1?.exist_with_us : false);
        formData.append("present_or_past", formData1?.present_or_past ? formData1?.present_or_past : "");
        // formData.append("fmember_details", JSON.stringify(cleanedFilledData));
        formData.append("fmember_details", "");

        formData.append("agree_to_the_term_and_condition", formData1?.agree_to_the_term_and_condition);
        formData.append("front_voter_card_upload", formData1?.uploadfile1 ? formData1?.uploadfile1 : "");
        formData.append("back_voter_card_upload", formData1?.uploadfile2 ? formData1?.uploadfile2 : "");

        if (formData.get("first_name") === "undefined" || formData.get("first_name") === "") {

            validationErrors.first_name = "Name is required"

        }

        // if (formData.get("guardian_name") === "undefined" || formData.get("guardian_name") === "") {
        //     validationErrors.guardian_name = "Father's/ Husband's Name is required"
        // }
        // if (isNumeric(formData.get("guardian_name"))) {
        //     validationErrors.guardian_name = "Enter Valid Name"
        // }
        // if (isNumeric(formData.get("middle_name"))) {
        //     validationErrors.middle_name = "Enter Valid Name"
        // }
        // if (isNumeric(formData.get("last_name"))) {
        //     validationErrors.last_name = "Enter Valid Name"

        // }
        if (formData.get("state") === "undefined") {
            validationErrors.state = "State is required"
        }
        // if (formData.get("location") === "undefined") {
        //     validationErrors.location = "Address is required"
        // }
        if (formData.get("mobile_no") === "undefined") {
            validationErrors.mobile_no = "Mobile Number is required"
        }
        if (formData.get("mobile_no") !== "undefined" && !isNumeric(formData.get("mobile_no")) && formData.get("mobile_no").length > 0) {
            validationErrors.mobile_no = "Mobile Number should only contain numbers"
        }
        if (isNumeric(formData.get("mobile_no")) && (formData.get("mobile_no").length < 10 || formData.get("mobile_no").length > 10)) {
            validationErrors.mobile_no = "Enter a valid Mobile Number"
        }
        if (formData.get("agree_to_the_term_and_condition") === "false" || formData.get("agree_to_the_term_and_condition") === "undefined") {
            validationErrors.agree_to_the_term_and_condition = "Please agree to the terms and conditions"
        }
        setErrors(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            const dropdata = await PostFormData(formData);
            if (dropdata?.status === 200 && dropdata?.data?.success === false) {
                openNotification(dropdata?.data?.message);

            } else if (dropdata?.status === 200) {
                submitpage(dropdata?.data?.message);
            } else {
                openNotification("something went wrong");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        }
    };

    const getDropDown = async () => {
        const dropdata = await GetDropDownApiState();
        if (dropdata?.status === 200) {
            setDropdowndata(dropdata?.data?.data);
        }
    }
    // const handleDistrict = async (value) => {
    //     const dropdata = await GetDropDownApiDistrict(value);
    //     if (dropdata?.status === 200) {
    //         setDropdowndata2(dropdata?.data?.data);
    //     }
    // }
    // const handleVidhanShabha = async (s1, d1) => {
    //     const dropdata = await GetDropDownApiVidhanShabha(s1, d1);
    //     if (dropdata?.status === 200) {
    //         setDropdowndata3(dropdata?.data?.data);
    //     }
    // }
    useEffect(() => {
        getDropDown();
    }, []);

    //upload 
    // const uploadImage = useRef(null);
    // const handleShow = (e) => {
    //     uploadImage.current.click();

    // };
    // const handleImageUploadChange = async (e) => {
    //     const maxAllowedSize = 1 * 1024 * 1024;
    //     if (e.target.files[0]?.size < maxAllowedSize) {
    //         const uploadedImage = e.target?.files[0];
    //         if (imageback === false) {
    //             setFormData1((prevData) => ({ ...prevData, uploadfile1: uploadedImage }))
    //             setImage1(uploadedImage);
    //             openNotification("Front Image Uploaded successfully !");
    //             // uploadImage.current = null;
    //         } else {
    //             setFormData1((prevData) => ({ ...prevData, uploadfile2: uploadedImage }))
    //             setImage2(uploadedImage);
    //             openNotification("Back Image Uploaded successfully !");
    //             // uploadImage.current = null;

    //         }



    //     } else {
    //         openNotification("File size is more than 1 mb !");
    //     }
    // };

    return (<>
        {contextHolder}
        <Modal
            title="Terms and Condition"
            centered
            width={650}
            className={`${styles.label} notranslate`}
            open={modalhistory}
            onCancel={() => {
                setModalhistory(false)
            }}
            footer={[
                <Button key="cancel" type="primary" onClick={() => {
                    setModalhistory(false);
                }}
                >
                    Ok
                </Button>,
            ]}>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>Object is to collate the data  and create voluntary group of people who are ready and willing to offer their services, at no pecuniary cost and expenses, for objectives in favour of society and the people.
                <br></br>
                <br></br>
                The data collated would be comprehended and assimilated to be used to meet the above objective.
                <br></br>
                <br></br>
                For doing so the data collected may include the location where the group may be accessed so that in the cause of emergency the group is available to render the optimal services expeditiously and efficaciously.
                <br></br>
                <br></br>
                Further, the location would guide us to make available the resources at the earliest so that the cause is attended to and the benefits are transferred to society at the earliest.
                <br></br>
                <br></br>
                The participation of the volunteers must be preceded with the following assumptions, subject however to completely indemnifying the inviter, for not being subject to any liability that may arise because of <br></br>
                a. message<br></br>
                b. Linking to the site<br></br>
                c. Participatory loss by the person<br></br>
                d. Disclosure of the information<br></br>
                e. Breach of information
                <br></br>
                <br></br>
                The volunteer shall render unconditional consent and waiver to the following:
                <br></br>
                <br></br>
                a. The sharing of the data<br></br>
                b. Usage of the data<br></br>
                c. Storage of the data<br></br>
                d. Replication/producing/reproducing/presenting/dissemination in any manner and form<br></br>
                e. With other persons who may deduce and frame such hypothesis that is necessary to derive any suggestion or inference and frame any equation<br></br>
                f. Access and counter check the data with the one kept and stored by the governmental authorities and private person.<br></br>
                g. To reach back physically and electronically with the person who is sharing the data<br></br>
                <br></br>
                <br></br>
                The volunteer assures that the :
                <br></br>
                <br></br>
                a. That the data being shared is voluntarily and consciously<br></br>
                b. That the data shared is not sensitive and confidential<br></br>
                c. That the data is otherwise available in public records<br></br>
                d. That the sharing of the data is incompliance of all applicable laws and regulations<br></br>
                e. That the sharing for the data is not pursuant to any pecuniary and commercial benefits<br></br>
                f. That the data being shared by the person is from a safe and a reliant machine<br></br>
                g. That the data being shared is protected with sufficient safeguards and passwords<br></br>
                h. That the data being shared is not having any malware or trojan horse and is not subject to any hacker in past<br></br>
                i. That the data being shared is not encrypted</p>
        </Modal>
        <Modal
            title="नियम और शर्तें "
            centered
            width={650}
            className={styles.label}
            open={modalhistory2}
            onCancel={() => {
                setModalhistory2(false)
            }}
            footer={[
                <Button key="cancel" type="primary" onClick={() => {
                    setModalhistory2(false);
                }}
                >
                    ठीक है
                </Button>,
            ]}>
            <p className="notranslate" style={{ textAlign: "justify", fontSize: "17px" }}>
                उद्देश्य डेटा एकत्र करना और ऐसे लोगों का स्वैच्छिक समूह बनाना है जो समाज और लोगों के पक्ष में उद्देश्यों के लिए, बिना किसी आर्थिक लागत और खर्च के अपनी सेवाएं देने के लिए तैयार और इच्छुक हैं।
                <br></br>
                <br></br>
                एकत्र किए गए डेटा को उपरोक्त उद्देश्य को पूरा करने के लिए उपयोग करने के लिए समझा और आत्मसात किया जाएगा।
                <br></br>
                <br></br>
                ऐसा करने के लिए एकत्र किए गए डेटा में वह स्थान शामिल हो सकता है जहां समूह तक पहुंचा जा सकता है ताकि आपातकालीन स्थिति में समूह शीघ्रता और प्रभावकारिता से इष्टतम सेवाएं प्रदान करने के लिए उपलब्ध हो।
                <br></br>
                <br></br>
                इसके अलावा, स्थान हमें जल्द से जल्द संसाधन उपलब्ध कराने के लिए मार्गदर्शन करेगा ताकि कारण पर ध्यान दिया जा सके और लाभ जल्द से जल्द समाज को हस्तांतरित किया जा सके।
                <br></br>
                <br></br>
                स्वयंसेवकों की भागीदारी निम्नलिखित मान्यताओं से पहले होनी चाहिए, हालांकि आमंत्रितकर्ता को पूरी तरह से क्षतिपूर्ति करने के अधीन होना चाहिए, क्योंकि वह किसी भी दायित्व के अधीन नहीं है जो कि उत्पन्न हो सकता है
                <br></br>
                a. संदेश<br></br>
                b. साइट से लिंक करना<br></br>
                c. व्यक्ति द्वारा सहभागी हानि<br></br>
                d. सूचना का प्रकटीकरण<br></br>
                e. सूचना का उल्लंघन<br></br>
                <br></br>
                स्वयंसेवक निम्नलिखित के लिए बिना शर्त सहमति और छूट प्रदान करेगा:
                <br></br>
                <br></br>
                a. डेटा का साझाकरण<br></br>
                b.डेटा का उपयोग<br></br>
                c. डेटा का भंडारण<br></br>
                d. किसी भी तरीके और रूप में प्रतिकृति/उत्पादन/पुनरुत्पादन/प्रस्तुति/प्रसार<br></br>
                e. अन्य व्यक्तियों के साथ जो ऐसी परिकल्पना निकाल सकते हैं और तैयार कर सकते हैं जो किसी सुझाव या अनुमान को प्राप्त करने और किसी भी समीकरण को तैयार करने के लिए आवश्यक है<br></br>
                f. सरकारी अधिकारियों और निजी व्यक्तियों द्वारा रखे गए और संग्रहित किए गए डेटा तक पहुंच और प्रति-जांच।<br></br>
                g. डेटा साझा करने वाले व्यक्ति तक भौतिक और इलेक्ट्रॉनिक रूप से वापस पहुंचने के लिए<br></br>
                <br></br>
                <br></br>
                स्वयंसेवक आश्वासन देता है कि:
                <br></br>
                <br></br>
                a. यह कि जो डेटा साझा किया जा रहा है वह स्वेच्छा से और जानबूझकर किया गया है<br></br>
                b. कि साझा किया गया डेटा संवेदनशील और गोपनीय नहीं है<br></br>
                c. यह कि डेटा अन्यथा सार्वजनिक रिकॉर्ड में उपलब्ध है<br></br>
                d. यह कि डेटा साझा करना सभी लागू कानूनों और विनियमों का अनुपालन नहीं है<br></br>
                e. यह कि डेटा साझा करना किसी भी आर्थिक और व्यावसायिक लाभ के अनुरूप नहीं है<br></br>
                f. कि व्यक्ति द्वारा साझा किया जा रहा डेटा एक सुरक्षित और भरोसेमंद मशीन से है<br></br>
                g. साझा किया जा रहा डेटा पर्याप्त सुरक्षा उपायों और पासवर्ड से सुरक्षित है<br></br>
                h. साझा किए जा रहे डेटा में कोई मैलवेयर या ट्रोजन हॉर्स नहीं है और यह अतीत में किसी हैकर के अधीन नहीं है<br></br>
                i. कि शेयर किया जा रहा डेटा एन्क्रिप्टेड नहीं है<br></br>
            </p>
        </Modal>
        {/* <input
            type="file"
            required
            style={{ display: "none" }}
            ref={uploadImage}
            onChange={(e) => handleImageUploadChange(e)}
            accept="image/png, image/jpeg, image/jpg"
        /> */}
        <Card
            bordered={true}
            style={{
                width: "100%",
                padding: window.innerWidth < 500 ? "0px" : "0px 15%"
            }}
        >
            <div className={styles.langdiv1}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1 className={styles.formh1}>{t('title')}</h1>
                </div>
                <div className={styles.langdiv2}>
                    <LanguageSelector className={styles.formdivLaginput} />
                </div>
            </div>

            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formdiv}>


                    <Row className='fatherRow'>
                        <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>
                                <label className={styles.starlabel}>
                                    Name</label><sapn className={styles.starspan}>*</sapn>
                            </div>

                            <input type="text" name="first_name" value={formData1.first_name} onChange={handleInputChange} placeholder="Enter Your Full Name" className={styles.formdiv3input} />
                            {validationErrors.first_name && <h4>{validationErrors.first_name}</h4>}
                        </Col>
                        <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>
                                <label className={styles.labelstar}>
                                    {t('gender')} </label>
                            </div>
                            <Select
                                showSearch
                                bordered={false}
                                allowClear
                                className={styles.formdiv3input}
                                placeholder="Select Gender"
                                onChange={(value) => {
                                    setFormData1((prevData) => ({ ...prevData, gender: value }))
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'male',
                                        label: 'Male',
                                    },
                                    {
                                        value: 'female',
                                        label: 'Female',
                                    },
                                    {
                                        value: 'other',
                                        label: 'Other',
                                    },

                                ]}
                            />
                        </Col>
                        <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>
                                <label className={styles.starlabel}>
                                    {t(`dob`)} </label>
                            </div>
                            <input type="date" name="dob" value={formData1.dob} onClick={(e) => e.currentTarget.showPicker()} onChange={handleInputChange} className={styles.formdiv3input} max={getCurrentDate()} style={{ paddingRight: "10px" }} />
                            {validationErrors.dob && <h4>{validationErrors.dob}</h4>}

                        </Col>
                        {/* <Col span={8}>
                            <input type="text" name="middle_name" value={formData1.middle_name} onChange={handleInputChange} placeholder={t('sname')} className={styles.formdiv3input} />
                            {validationErrors.middle_name && <h4>{validationErrors.middle_name}</h4>}

                        </Col>
                        <Col span={8}>
                            <input type="text" name="last_name" value={formData1.last_name} onChange={handleInputChange} placeholder={t('lname')} className={styles.formdiv3input} />
                            {validationErrors.last_name && <h4>{validationErrors.last_name}</h4>}

                        </Col> */}
                    </Row>

                    <Row className='fatherRow'>
                        {/* <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>

                                <label className={styles.labelstar}>
                                    {t('fatherName')}
                                </label><sapn className={styles.starspan}>*</sapn>
                            </div>

                            <input type="text" name="guardian_name" placeholder="Father's/ Husband's Name" value={formData1.guardian_name} onChange={handleInputChange} className={styles.formdiv3input} />
                            {validationErrors.guardian_name && <h5>{validationErrors.guardian_name}</h5>}

                        </Col> */}
                        <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>
                                <label className={styles.starlabel}>
                                    {t('mobile')}</label><sapn className={styles.starspan}>*</sapn>
                            </div>

                            <input type="tel" name="mobile_no" placeholder="Enter Your Mobile No" value={formData1.mobileNumber} onChange={handleInputChange} className={styles.formdiv3input} />
                            {validationErrors.mobile_no && <h4>{validationErrors.mobile_no}</h4>}


                        </Col>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('profession')}
                                <Select
                                    showSearch
                                    bordered={false}
                                    allowClear
                                    className={styles.formdiv3input}
                                    placeholder={t('selectprofession')}

                                    onChange={(value) => {
                                        setFormData1((prevData) => ({ ...prevData, profession: value }))
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            {/* <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}> */}
                                            <Input
                                                placeholder={t(`selectprofession`)}
                                                ref={inputRef}
                                                value={name}
                                                required
                                                onChange={onNameChange}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                Add Profession
                                            </Button>
                                            {/* </Space> */}
                                        </>
                                    )}
                                    options={items.map((item) => ({
                                        label: item,
                                        value: item,
                                    }))}
                                />

                            </label>
                        </Col>

                        <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>
                                <label className={styles.starlabel}>
                                    Address </label>
                            </div>
                            <input type="text" name="location" value={formData1.location} placeholder="Enter Your Address" onChange={handleInputChange} className={styles.formdiv3input} />
                            {/* {validationErrors.location && <h4>{validationErrors.location}</h4>} */}
                        </Col>
                    </Row>
                    <Row className='fatherRow'>


                        <Col span={8}>
                            <label className={styles.label}>
                                Category
                                <Select
                                    showSearch
                                    bordered={false}
                                    allowClear
                                    className={styles.formdiv3input}
                                    placeholder="Select Category"
                                    onChange={(value) => {
                                        setFormData1((prevData) => ({ ...prevData, category: value }))
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'general',
                                            label: 'General',
                                        },
                                        {
                                            value: 'obc',
                                            label: 'OBC',
                                        },
                                        {
                                            value: 'sc/st',
                                            label: 'SC / ST',
                                        },
                                        {
                                            value: 'other',
                                            label: 'Other',
                                        },
                                    ]}
                                />
                            </label>
                        </Col>

                        <Col span={8}>
                            <label className={styles.label}>
                                {t('caste')}
                                <input type="text" placeholder="Enter Your Caste" name="caste" value={formData1.caste} onChange={handleInputChange} className={styles.formdiv3input} />
                            </label>
                        </Col>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t(`maritalStatus`)}
                                <Select
                                    showSearch
                                    bordered={false}
                                    allowClear
                                    className={styles.formdiv3input}
                                    placeholder="Select Marital Status"
                                    onChange={(value) => {
                                        setFormData1((prevData) => ({ ...prevData, maritalStatus: value }))
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'married',
                                            label: 'Married',
                                        },
                                        {
                                            value: 'unmarried',
                                            label: 'Unmarried',
                                        },
                                        {
                                            value: 'divorced',
                                            label: 'Divorced',
                                        },
                                    ]}
                                />
                            </label>
                        </Col>

                    </Row>
                    <Row className='fatherRow'>
                        <Col span={8} className={styles.label}>
                            <div className={styles.stardiv}>
                                <label className={styles.starlabel}>
                                    {t('state')} </label><sapn className={styles.starspan}>*</sapn>
                            </div>
                            <Select
                                showSearch
                                bordered={false}
                                allowClear
                                // required
                                className={styles.formdiv3input}
                                placeholder="Select a State"
                                onChange={
                                    (value) => {
                                        // formData.append('state', value);
                                        setFormData1((prevData) => ({ ...prevData, state: value }));
                                        // setDropdowndata2([]);
                                        // setDropdowndata3([]);
                                        // handleDistrict(value);
                                        const updatedObject = { ...validationErrors };
                                        delete updatedObject["state"];
                                        setValidationErrors(updatedObject);

                                    }
                                }

                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={dropdowndata?.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt,
                                    }
                                })}
                            />
                            {validationErrors.state && <h4>{validationErrors.state}</h4>}

                        </Col>
                        {/* <Col span={8}>

                            <label className={styles.label}>
                                {t('district')}
                                <Select
                                    showSearch
                                    bordered={false}
                                    allowClear
                                    className={styles.formdiv3input}
                                    placeholder="Select a District"
                                    onChange={
                                        (value) => {
                                            setFormData1((prevData) => ({ ...prevData, district: value }));
                                            setDropdowndata3([]);
                                            handleVidhanShabha(formData1?.state, value)
                                            // formData.append('district', value);
                                        }
                                    }
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={dropdowndata2?.map((opt) => {
                                        return {
                                            value: opt,
                                            label: opt,
                                        }
                                    })}
                                />
                            </label>
                        </Col> */}
                        <Col span={8}>
                            <label className={styles.label}>
                                Voter Id Number
                            </label>
                            <input type="text" name="voter_id" placeholder="Enter Your voter No" value={formData1.voter_id} onChange={handleInputChange} className={styles.formdiv3input} />

                        </Col>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('vidhanShabha')}

                                <input type="text" name="vidhansabha" placeholder="Enter Your Vidhan Shabha" value={formData1.vidhansabha} onChange={handleInputChange} className={styles.formdiv3input} />
                            </label>
                            {/* <label className={styles.label}>
                                {t('vidhanShabha')}
                                <Select
                                    showSearch
                                    bordered={false}
                                    allowClear
                                    className={styles.formdiv3input}
                                    placeholder="Select a Vidhan Shabha"
                                    onChange={
                                        (value) => {
                                            setFormData1((prevData) => ({ ...prevData, vidhansabha: value }));
                                            // setDropdowndata3([]);
                                            // handleVidhanShabha(formData1?.state, value)
                                            // formData.append('district', value);

                                        }
                                    }
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={dropdowndata3?.map((opt) => {
                                        return {
                                            value: opt,
                                            label: opt,
                                        }
                                    })}
                                />
                            </label> */}
                        </Col>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('existingPosition')}
                                <Select
                                    bordered={false}
                                    className={styles.formdiv3input}
                                    defaultValue="false"
                                    onChange={(value) => {
                                        setFormData1((prevData) => ({ ...prevData, exist_with_us: value }))
                                    }}
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'Yes',
                                        },
                                        {
                                            value: 'false',
                                            label: 'No',
                                        },

                                    ]}
                                />
                            </label>
                        </Col>

                    </Row>

                    {/* <Row className='fatherRow'>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('facebook')}
                                <input type="text" name="facebookUrl" placeholder="Enter Your Facebook Profile Link" value={formData1.facebookUrl} onChange={handleInputChange} className={styles.formdiv3input} />
                            </label>
                        </Col>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('twitter')}
                                <input type="text" name="twitterUrl" placeholder="Enter Your Twitter Profile Link" value={formData1.twitterUrl} onChange={handleInputChange} className={styles.formdiv3input} />

                            </label>
                        </Col>
                       
                    </Row> */}

                    {/* <Row className='fatherRow'>

                        <Col span={8}>
                            <label className={styles.label}>
                                {t(`aniversaryDate`)}
                                <input type="date" name="anviDate" value={formData1.anviDate} onChange={handleInputChange} className={styles.formdiv3input} />
                            </label>
                        </Col> 
                        <Col span={8}>
                            <label className={styles.label}>
                                {t(`maritalStatus`)}
                                <Select
                                    showSearch
                                    bordered={false}
                                    allowClear
                                    className={styles.formdiv3input}
                                    placeholder="Select Marital Status"
                                    onChange={(value) => {
                                        setFormData1((prevData) => ({ ...prevData, maritalStatus: value }))
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'married',
                                            label: 'Married',
                                        },
                                        {
                                            value: 'unmarried',
                                            label: 'Unmarried',
                                        },
                                        {
                                            value: 'divorced',
                                            label: 'Divorced',
                                        },
                                    ]}
                                />
                            </label>
                        </Col>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('existingPosition')}
                                <select name="exist_with_us" value={formData1.exist_with_us}
                                    onChange={(e) => {
                                        setFormData1((prevData) => ({ ...prevData, exist_with_us: e.target.value }))
                                        // setPosition(e.target.value)

                                    }}
                                    className={`${styles.formdiv3input} ${styles.selectinput}`}  >
                                    <option value="true">Yes</option>
                                    <option selected value="false">No</option>
                                </select>
                            </label>
                        </Col>

                    </Row> */}


                    {/* <Row className='fatherRow'>
                        <Col span={8}>
                            <label className={styles.label}>
                                {t('existingPosition')}
                                <select name="exist_with_us" value={formData1.exist_with_us}
                                    onChange={(e) => {
                                        setFormData1((prevData) => ({ ...prevData, exist_with_us: e.target.value }))
                                        // setPosition(e.target.value)

                                    }}
                                    className={`${styles.formdiv3input} ${styles.selectinput}`}  >
                                    <option value="true">Yes</option>
                                    <option selected value="false">No</option>
                                </select>
                            </label>
                        </Col>
                        {position === "true" &&
                            <Col span={8}>
                                <label className={styles.label}>
                                    Position
                                    <select name="present_or_past" value={formData1.present_or_past}
                                        onChange={(e) => {
                                            setFormData1((prevData) => ({ ...prevData, present_or_past: e.target.value }))

                                        }}
                                        className={`${styles.formdiv3input} ${styles.selectinput}`} >
                                        <option value="">
                                            Select Position
                                        </option>
                                        <option value="present">In Present</option>
                                        <option value="past">In Past</option>
                                    </select>
                                </label>
                            </Col>}


                    </Row> */}

                    {/* family details */}

                    {/* <div className={styles.tablediv}>
                        <Row>
                            <Col span={12}>
                                <label className={styles.label}>
                                    {t(`family`)}</label>
                            </Col>
                            <Col span={12} style={{ textAlign: "end" }}>
                                <button onClick={addRow} className={styles.addrowButton} >{t(`addmember`)}</button>

                            </Col>
                        </Row>
                        <Row>
                            <div className="table-container">
                                <table className="responsive-table">
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr >
                                            <th> <label className={styles.label}>
                                                {t(`name`)}</label></th>
                                            <th> <label className={styles.label}>
                                                {t(`relation`)}</label></th>
                                            <th> <label className={styles.label}>
                                                {t(`dob`)}</label></th>

                                            <th> <label className={styles.label}>
                                                {t(`profession`)}</label></th>
                                            <th> <label className={styles.label}>
                                                {t(`mobile`)}</label></th>
                                            <th> <label className={styles.label}>
                                                {t(`action`)}</label></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: "initial" }}>
                                        {familyData.map((row, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={row.name}
                                                        placeholder="Enter Member Name"
                                                        className={styles.familyinput}
                                                        onChange={(e) => handleInputChangeofTable(index, "name", e.target.value)}
                                                    />
                                                    {validationErrors.name && <h4>{validationErrors.name}</h4>}
                                                </td>
                                                <td>
                                                    <select name="relation" value={row.relation}
                                                        onChange={(e) => handleInputChangeofTable(index, 'relation', e.target.value)}

                                                        className={styles.familyinput}  >
                                                        <option value="">Select Relation with Member </option>

                                                        <option value="spouse">Spouse</option>
                                                        <option value="daughter">Daughter</option>
                                                        <option value="son">Son</option>
                                                        <option value="father">Father</option>
                                                        <option value="mother">Mother</option>
                                                        <option value="other">other</option>
                                                    </select>

                                                </td>

                                                <td>
                                                    <input
                                                        type="date"
                                                        value={row.dob}
                                                        className={styles.familyinput}
                                                        onChange={(e) => handleInputChangeofTable(index, 'dob', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        showSearch
                                                        bordered={false}
                                                        allowClear
                                                        value={row.profession}
                                                        className={styles.familyinput}

                                                        onChange={(e) => handleInputChangeofTable(index, 'profession', e)}

                                                        filterOption={(input, option) =>
                                                            (option?.label ?? "")
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Input
                                                                    placeholder={t(`selectprofession`)}
                                                                    ref={inputRef}
                                                                    value={name}
                                                                    required
                                                                    onChange={onNameChange}
                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                />
                                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                                    Add Profession
                                                                </Button>
                                                            </>
                                                        )}
                                                        options={items.map((item) => ({
                                                            label: item,
                                                            value: item,
                                                        }))}
                                                    />


                                                </td>

                                                <td>
                                                    <input
                                                        type="tel"
                                                        value={row.mobile}
                                                        placeholder="Enter Mobile No"
                                                        className={styles.familyinput}

                                                        onChange={(e) => handleInputChangeofTable(index, 'mobile', e.target.value)}
                                                    />
                                                    {validationErrors.mobile && <h4>{validationErrors.mobile}</h4>}
                                                </td>
                                                <td>
                                                    <div className={styles.removediv}>
                                                        <MdDelete size={30} onClick={() => removeRow(index)} color='red' /></div>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </Row>

                    </div> */}
                    {/* <Row className='uploadRow'>
                        <Col className={styles.uploadCol}
                            span={8}>
                            <label className={styles.label}>
                                <span style={{ marginTop: "5px" }}>
                                    Voter Id Number
                                </span>
                            </label>
                            <input type="text" name="voter_id" placeholder="Enter Your voter No" value={formData1.voter_id} onChange={handleInputChange} className={styles.formdiv3input} />

                        </Col>
                        <Col className={styles.uploadCol}
                            span={12}>
                            <div className={styles.uploadbuttons}>
                                {image1 ?
                                    <div className={styles.uploaddiv}>
                                        <span style={{ marginTop: "5px", marginRight: "5px", color: "green" }}>
                                            <img src={URL.createObjectURL(image1)} alt="Uploaded File" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                                        </span>
                                        <span >
                                            <RxCross2 style={{ backgroundColor: "rgba(34, 64, 155, 1)", borderRadius: "15px", color: "white" }} onClick={() => setImage1("")} />
                                        </span></div>
                                    : <button
                                        type="button"
                                        color="info"
                                        size="sm"
                                        onClick={() => {
                                            setImageback(false);
                                            handleShow();
                                        }}
                                        // disabled={disableUpload}
                                        className={styles.buttondivupload}>
                                        <div className={styles.uploaddiv}>
                                            <span style={{ marginTop: "5px", marginRight: "5px" }}>
                                                Front
                                            </span>
                                            <span style={{ marginTop: "5px" }}>
                                                <TbUpload />

                                            </span>
                                        </div>
                                    </button>}
                                {image2 ?

                                    <div className={styles.uploaddiv}>
                                        <span style={{ marginTop: "5px", marginRight: "5px", color: "green" }}>
                                            <img src={URL.createObjectURL(image2)} alt="Uploaded File" style={{ maxWidth: '150px', maxHeight: '150px' }} />

                                        </span>
                                        <span >
                                            <RxCross2 style={{ backgroundColor: "rgba(34, 64, 155, 1)", borderRadius: "15px", color: "white" }} onClick={() => setImage2("")} />
                                        </span></div>
                                    : <button
                                        type="button"
                                        color="info"
                                        size="sm"

                                        onClick={() => {
                                            setImageback(true);

                                            handleShow();
                                        }}
                                        // disabled={disableUpload}
                                        className={styles.buttondivupload}>
                                        <div className={styles.uploaddiv}>
                                            <span style={{ marginTop: "5px", marginRight: "5px" }}>
                                                Back
                                            </span>
                                            <span style={{ marginTop: "5px" }}>
                                                <TbUpload />

                                            </span>

                                        </div>
                                    </button>}
                            </div>
                        </Col>

                    </Row> */}


                    <Row className='fatherRow'>
                        {/* <div className="notranslate"> */}
                        <Col span={24} style={{ display: "flex" }} className='notranslate t&ccol'>
                            <input type="checkbox" name="agree_to_the_term_and_condition" className={styles.checkbox}
                                onChange={(e) => {
                                    setFormData1((prevData) => ({ ...prevData, agree_to_the_term_and_condition: e.target.checked }))
                                    if (e.target.checked) {
                                        const updatedObject = { ...validationErrors };
                                        delete updatedObject["agree_to_the_term_and_condition"];
                                        setValidationErrors(updatedObject);
                                    }
                                }} />
                            <label className={styles.agreediv}>
                                <span style={{ marginTop: "5px" }}>
                                    I agree to the <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                        setModalhistory(true);
                                    }}>terms and conditions </span>
                                    / मैं <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                        setModalhistory2(true);
                                    }}>नियमों और शर्तों</span> से सहमत हूं |
                                </span>
                            </label>

                        </Col>
                        {validationErrors.agree_to_the_term_and_condition && <h4>{validationErrors.agree_to_the_term_and_condition}</h4>}
                    </Row>
                    <Row className={styles.submitrow}>
                        <Col span={24} className={styles.submitbutton}>
                            <div className={styles.buttondiv}>
                                <button type="submit" className={styles.button}>Submit</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>
        </Card>

    </>

    )

}
