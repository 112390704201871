// LanguageSelector.jsx

import React, { useEffect, useRef } from 'react';
import { notification } from 'antd';


const LanguageSelector = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;

        script.onerror = (error) => {
            console.error('Error loading Google Translate script:', error);
        };

        document.body.appendChild(script);
        window.googleTranslateElementInit = function () {
            try {
                new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'en,hi' }, 'google_translate_element');
            } catch (error) {
                window.location.reload();
            }
        };
    }, []);

    return (
        <div>
            <div id="google_translate_element" className="notranslate"></div>
        </div>
    );
};

export default LanguageSelector;
