import { Card } from 'antd'
import React from 'react'
import styles from './SubmitPage.module.css';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Checkmark } from 'react-checkmark'


export default function SubmitPage() {
    const { t } = useTranslation();
    const location = useLocation();
    const data = location.state;

    return (
        <>
            <Card
                bordered={true}
                style={{
                    width: "100%",
                    padding: window.innerWidth < 500 ? "0px" : "0px 15%"
                }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <h1 className={styles.formh1}>Form Submitted Successfully</h1>
                </div>


                <div className={styles.checkmark}>
                    <Checkmark size={window.innerWidth < 500 ? "150px" : "206px"} />
                    <h2 className={styles.formh2}>{data}</h2>


                </div>

            </Card>

        </>
    )
}

