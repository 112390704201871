import i18n from "i18next";
import { initReactI18next } from "react-i18next";


i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translation: {
                title: "Volunteer Form",
                lang: "Language",
                fname: "First Name",
                sname: "Middle Name",
                lname: "Last Name",
                name: "Name",
                profession: "Profession",
                selectprofession: "Please Select Profession",
                selectage: "Please Select Age",
                dob: "Date Of Birth",
                selectgender: "Please Select Gender",
                above55: "55 and above",
                male: "Male",
                female: "Female",
                other: "other",
                caste: "Caste",
                category: "Category",
                facebook: "Facebook Profile Link",
                twitter: "Twitter Profile Link",
                getlocation: "Click to get your Current Location",
                aniversaryDate: "Anniversary Date",
                fatherName: "Father's/ Husband's Name",
                gender: "Gender",
                maritalStatus: "Marital Status",
                married: "Married",
                unmarried: "Unmarried",
                divorced: "Divorced",
                family: "Family Details",
                relation: "Relation",
                addmember: "Add Member",

                action: "Remove",
                age: "Age",
                location: "Location",
                state: "State",
                district: "District",
                vidhanShabha: "Vidhan Shabha",
                mobile: "Mobile Number",
                existingPosition: "If any existing position with us",
                uploadimgtext: "Kindly Upload Your Voter Card ID:"
            }
        },
        // hi: {
        //     translation: {
        //         title: "बीएसपी वालंटियर",
        //         lang: "भाषा",
        //         fname: "पहला नाम",
        //         sname: "मध्य नाम",
        //         lname: "उपनाम",
        //         name: "नाम",
        //         profession: "व्यवसाय",
        //         dob: "जन्म की तिथि",
        //         selectprofession: "कृपया व्यवसाय चुनें",
        //         selectage: "कृपया आयु चुनें",
        //         above55: "55 और उससे अधिक",
        //         selectgender: "कृपया लिंग चुनें",
        //         facebook: "फेसबुक प्रोफ़ाइल लिंक",
        //         twitter: "ट्विटर प्रोफ़ाइल लिंक",
        //         aniversaryDate: "वर्षगांठ की तिथि",
        //         maritalStatus: "वैवाहिक स्थिति",
        //         married: "विवाहित",
        //         unmarried: "अविवाहित",
        //         divorced: "तलाकशुदा",
        //         family: "पारिवारिक विवरण",
        //         relation: "संबंध",
        //         action: "हटाएँ",
        //         addmember: "सदस्य जोड़ें",
        //         getlocation: "अपना वर्तमान स्थान प्राप्त करने के लिए टैप करें",
        //         male: "पुरुष",
        //         female: "महिला",
        //         other: "अन्य",
        //         caste: "जाति",
        //         category: "वर्ग",
        //         uploadimgtext: "कृपया अपना वोटर कार्ड अपलोड करें:",
        //         fatherName: "पिता/पति का नाम",
        //         gender: "लिंग",
        //         age: "आयु",
        //         location: "स्थान",
        //         state: "राज्य",
        //         district: "ज़िला",
        //         vidhanShabha: "विधान सभा",
        //         mobile: "मोबाइल नंबर",
        //         existingPosition: "यदि हमारे पास कोई मौजूदा स्थिति है",
        //     }
        // },

    }

})

