
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,

} from "react-router-dom";
import VolunteerForm from './Components/VolunteerForm';
import SubmitPage from './Components/SubmitPage'
function App() {
  return (
    <>
      <Router>

        <Routes>
          <Route exact path="/"
            element={<VolunteerForm />} />

          <Route exact path="/submitted"
            element={<SubmitPage />} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
